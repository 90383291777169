//物联网产品列表
const getIotProductList = `/gateway/hc-device/iot/product/list`;

//新增物联网产品
const addProList = `/gateway/hc-device/iot/product/submit`;
//获取设备凭证
const getDeviceCredentials = (url) => {
  return `/gateway/hc-device/iot/device/${url.deviceId}/credentials`;
};
//删除产品
const deleteProUrl = (url) => {
  return `/gateway/hc-device/iot/product/${url.deviceId}/delete`;
};
//产品详情
const getProInfoUrl = (url) => {
  return `/gateway/hc-device/iot/product/${url.deviceId}/detail`;
};
//产品下拉列表 category  （类别）code=auth  （认证）code=net（联网）code=node（节点类型） code=operator（符号）
const proAllListUrl = `/gateway/blade-system/dict/list`;
//属性列表
const valueListUrl = `/gateway/hc-device/iot/product/attr/list`;
//保存模型信息
const saveModelUrl = `/gateway/hc-device/iot/product/model`;
export {
  getIotProductList,
  addProList,
  getDeviceCredentials,
  deleteProUrl,
  getProInfoUrl,
  proAllListUrl,
  valueListUrl,
  saveModelUrl,
};
